import React from 'react';
import moment from 'moment';

import { CircleCountdownTimer } from './CircleCountdownTimer';
import './FlipClock.css'

// import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
// import '@leenguyen/react-flip-clock-countdown/dist/index.css';

export const CountdownTimer = ({ endDate, title, daysOnly = false }) => {
  // const [isSafari, setIsSafari] = React.useState(true);

  //We will use the circle component for everything for now, flipbook has too many issues across browsers and devices

  // React.useEffect(() => {
  // if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) setIsSafari(true);
  // }, [])
  const renderOptions = [
    true,                     //Show days
    daysOnly ? false : true, //Show hours
    daysOnly ? false : true, //Show minutes
    daysOnly ? false : true  //Show seconds
  ]

  const goalDate = daysOnly ? moment(endDate).startOf('day') : moment(endDate);
  const goalDateFormatted = moment(endDate).format(daysOnly ? 'dddd Do MMMM YYYY' : 'dddd Do MMMM YYYY, h:mm:ss A');
  return (
    <div className="flipclock m-0.25">
      <p className="flipclock-subtitle bold 0.75 mb-0">{goalDateFormatted}</p>
      <p className="flipclock-title">{title}</p>
      {/* {!isSafari ? (
          <FlipClockCountdown
            labels={['Days', 'Hours', 'Minutes', 'Seconds']}
            labelStyle={{ color: '#000' }}
            showSeparators={true}
            separatorStyle={{ color: '#000' }}
            digitBlockStyle={{
              backgroundColor: 'rgb(35, 212, 255)',
              height: 55,
              width: 30,
              fontSize: 24
            }}
            dividerStyle={{ height: 0 }}
            renderMap={renderOptions}
            to={goalDate}
          >
            <p className="flipclock-title">
              This exam has finished
            </p>
          </FlipClockCountdown>
      ) : ( */}
      <CircleCountdownTimer endDate={goalDate} daysOnly={daysOnly} />
      {/* )} */}
    </div>
  )
}